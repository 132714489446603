export enum ActionsTypesLoadDataHome {
  HOME_DATA = 'HOME_DATA',
}

export function loadDataHome(payload: any) {
  return {
    type: ActionsTypesLoadDataHome.HOME_DATA,
    payload,
  };
}
