import { ActionsTypesLoadDataGlobal } from '../actions/global';

const init = {};

export function loadDataGlobalReducer(state = init, action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionsTypesLoadDataGlobal.GLOBAL_DATA:
      return { ...state, ...payload };

    default:
      return state;
  }
}

export function toggleEditMode(state = init, action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionsTypesLoadDataGlobal.EDIT_MODE:
      return { ...state, ...payload };

    default:
      return state;
  }
}
