import { compose, createStore } from 'redux';
import reducers from './reducers/index';

export { auth, cleanAuth } from './actions/auth';
export {
  cleanGallery,
  cleanImage,
  loadImages,
  showImage,
} from './actions/galleryActions';
export { loadDataGlobal, toggleEditMode } from './actions/global';
export { loadDataHome } from './actions/loadDataHome';
export { toggleDeskDrawer, toggleMobileDrawer } from './actions/menu';

const composeEnhancers =
  (typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default createStore(reducers, composeEnhancers());
