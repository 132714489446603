import { ActionsTypes } from '../actions/galleryActions';

const initImage: Image = {
  link: '',
  open: false,
  loading: false,
  index: 0,
  category: '',
};

export function galleryReducer(state: Image = initImage, action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionsTypes.SHOW_IMAGE:
      return { ...state, ...payload };
    case ActionsTypes.CLEAN_IMAGE:
      return { link: '', open: false, loading: false, index: 0 };
    default:
      return state;
  }
}

export function loadImagesReducer(state: IGallery[] = [], action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionsTypes.LOAD_IMAGES:
      return [...state, ...payload];
    case ActionsTypes.CLEAN_GALLERY:
      return [];
    default:
      return [...state];
  }
}
