import { MenuActionsTypes } from '../actions/menu';

const init: IShowMenu = {
  showMobile: false,
  showDesk: false,
};

export function menuReducer(state = init, { type, payload }: IAction) {
  switch (type) {
    case MenuActionsTypes.TOGGLE_DESK_DRAWER:
      return {
        ...state,
        showDesk: payload,
      };
    case MenuActionsTypes.TOGGLE_MOBILE_DRAWER:
      return {
        ...state,
        showMobile: payload,
      };
    default:
      return state;
  }
}
