export enum ActionsTypesLoadDataGlobal {
  GLOBAL_DATA = 'GLOBAL_DATA',
  EDIT_MODE = 'GLOBAL_DATA',
}

export function loadDataGlobal(payload: any) {
  return {
    type: ActionsTypesLoadDataGlobal.GLOBAL_DATA,
    payload,
  };
}

export function toggleEditMode(payload: any) {
  return {
    type: ActionsTypesLoadDataGlobal.EDIT_MODE,
    payload,
  };
}
