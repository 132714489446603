export enum AuthActionTypes {
  USER_AUTH = 'USER_AUTH',
  CLEAN_AUTH = 'CLEAN_AUTH',
}

export function auth(data: any) {
  return {
    type: AuthActionTypes.USER_AUTH,
    payload: data,
  };
}

export function cleanAuth() {
  return {
    type: AuthActionTypes.CLEAN_AUTH,
  };
}
