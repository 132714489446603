export enum ActionsTypes {
  SHOW_IMAGE = 'SHOW_IMAGE',
  LOAD_IMAGES = 'LOAD_IMAGES',
  CLEAN_GALLERY = 'CLEAN_GALLERY',
  CLEAN_IMAGE = 'CLEAN_IMAGE',
}

export function loadImages(images: IGallery[]) {
  return {
    type: ActionsTypes.LOAD_IMAGES,
    payload: images,
  };
}

export function showImage(image: Image) {
  return {
    type: ActionsTypes.SHOW_IMAGE,
    payload: image,
  };
}

export function cleanImage() {
  return { type: ActionsTypes.CLEAN_IMAGE };
}

export function cleanGallery() {
  return { type: ActionsTypes.CLEAN_GALLERY };
}
