import { AuthActionTypes } from '../actions/auth';

const init = {
  loading: false,
  error: '',
  show: false,
  editMode: false,
};

export function authReducer(state = init, action: IAction) {
  const { type, payload } = action;
  switch (type) {
    case AuthActionTypes.USER_AUTH:
      return { ...state, ...payload };
    case AuthActionTypes.CLEAN_AUTH:
      return { ...init };
    default:
      return state;
  }
}
