import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { galleryReducer, loadImagesReducer } from './gallery';
import { toggleEditMode, loadDataGlobalReducer } from './global';
import { menuReducer } from './menu';

const reducers = combineReducers({
  image: galleryReducer,
  images: loadImagesReducer,
  menu: menuReducer,
  global: loadDataGlobalReducer,
  auth: authReducer,
  toggleEditMode: toggleEditMode,
});

export default reducers;

export type RootReducer = ReturnType<typeof reducers>;
