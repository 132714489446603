export enum MenuActionsTypes {
  TOGGLE_DESK_DRAWER = 'TOGGLE_DESK_DRAWER',
  TOGGLE_MOBILE_DRAWER = 'TOGGLE_MOBILE_DRAWER',
}

export function toggleDeskDrawer(props: boolean) {
  return {
    type: MenuActionsTypes.TOGGLE_DESK_DRAWER,
    payload: props,
  };
}

export function toggleMobileDrawer(props: boolean) {
  return {
    type: MenuActionsTypes.TOGGLE_MOBILE_DRAWER,
    payload: props,
  };
}
